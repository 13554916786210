html {
  box-sizing: border-box;
  position: relative;
  min-height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
}

body {
  font-family: 'Roboto';
}

img {
  max-width: 100%;
}

